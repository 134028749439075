<template>
        <v-card class="mx-auto pt-5">
            <template v-if="abrechnungen" class="mx-auto pa-5">
              <template v-if="toggleNochNichtEingereicht" >
                <v-data-table :headers="headers_nicht_eingereicht"
                              :items="abrechnungen_nicht_eingereicht"
                              item-key="id"
                              :search="suche_nicht_eingereicht"
                              dense
                >
                  <template v-slot:top>

                    <v-toolbar flat dense>
                      <v-row class="ma-0 pa-0">
                        <v-col  xs="12" md="3" class="ma-0 pa-0">
                          <v-row class="ma-0 pa-0">
                            <v-col class="ma-0 pa-0">
                              <section>
                                <h1>Noch nicht Eingereiche Abrechnungen</h1>
                              </section>
                            </v-col>

                          </v-row>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col  xs="12" md="4" class="ma-0 pa-0">
                          <v-row class="ma-0 pa-0" >
                            <v-spacer></v-spacer>
                              <v-switch
                                  v-model="toggleNochNichtEingereicht"
                                  label="nicht Eingereicht"
                                  class="ma-0 pa-2"
                                  color="blue"
                                  dense
                              ></v-switch>
                            <v-btn class="mx-1" fab dark small elevation="2" color="accent" @click="reload">
                              <v-icon dark>
                                mdi-refresh
                              </v-icon>
                            </v-btn>
                            <v-text-field v-model="suche" append-icon="mdi-magnify" label="Suchbegriff eingeben..." outlined rounded dense hide-details ></v-text-field>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </template>
              <template v-else>

              <v-data-table :headers="headers"
                            :items="abrechnungen"
                            item-key="id"
                            :search="suche"
                            sort-by="competition.competitionStartDate"
                            :sort-desc="true"
                            dense
              >
                <template v-slot:top>

                  <v-toolbar flat dense>
                    <v-row class="ma-0 pa-0">
                      <v-col  xs="12" md="3" class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0">
                          <v-col class="ma-0 pa-0">
                        <section>
                          <h1>Offene Abrechnungen</h1>
                        </section>
                          </v-col>

                        </v-row>
                      </v-col>

                      <v-spacer></v-spacer>

                      <v-col  xs="12" md="4" class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0" >
                          <v-spacer></v-spacer>
                            <v-switch
                                v-model="toggleNochNichtEingereicht"
                                label="nicht Eingereicht"
                                class="ma-0 pa-2"
                                color="blue"
                            ></v-switch>
                          <v-btn class="mx-1" fab dark small elevation="2" color="accent" @click="reload">
                            <v-icon dark>
                              mdi-refresh
                            </v-icon>
                          </v-btn>
                          <v-text-field v-model="suche" append-icon="mdi-magnify" label="Suchbegriff eingeben..." outlined rounded dense hide-details ></v-text-field>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-for="header in headers.filter((e) => e.hasOwnProperty('typ'))" v-slot:[`item.${header.value}`]="{ header, value,item }" >
                  <template v-if="header.typ === 'euro'">
                    {{ formatMoney(value||0) }}
                  </template>
                  <template v-else-if="header.typ === 'euro-spesen'">
                    <v-btn v-if="item.receipt" :key="header.typ" :href="backend+item.receipt[0].url" target="_blank" depressed fab x-small ><v-icon>mdi-paperclip</v-icon></v-btn> {{ formatMoney(value||0) }}
                  </template>
                  <template v-else-if="header.typ === 'datum'">
                    {{ getLocalDateString(value) }}
                  </template>
                  <template v-else-if="header.typ === 'status'" >
                    <v-menu :key="header.typ"  :close-on-click="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip  :key="header.typ" :color="getStatusFarbe(value)" small v-bind="attrs" v-on="on">{{value}}
                          <v-icon right small>{{ getStatusIcon(value) }}</v-icon>
                        </v-chip>
                      </template>

                      <v-list dense >
                        <v-subheader>Status ändern:</v-subheader>
                        <v-list-item-group
                            v-model="newStatus"
                        >
                        <v-list-item v-for="(status,i) in stati" :key="i" @click="setNewStatus(status,item)">
                          <v-list-item-icon>
                            <v-icon v-text="status.icon"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content >
                            <v-list-item-title v-text="status.text"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>

                  </template>
                  <template v-else-if="header.typ === 'info'" >
                      <v-icon :key="header.typ" :color="item.info && item.info.trim().length>0?'blue darken-1':''" @click="openDialogItemInfo(item)">
                        mdi-comment-alert
                      </v-icon>

                  </template>
                  <template v-else>
                     {{ value }}
                  </template>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-hover>
                    <template v-slot:default="{ hover, props }">
                      <v-icon v-bind="props" @click="deleteAbrechnung(item)" :color="hover ? 'red darken-2' : undefined">mdi-delete</v-icon>
                    </template>
                  </v-hover>
                </template>
              </v-data-table>
              </template>

            </template>
            <template v-else>
                <section class="text-center d-flex flex-column align-center justify-center">
                    <p>Abrufen der Inhalte</p>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </section>
            </template>
            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5 ">Wollen Sie diese Abrechnung wirklich löschen?</v-card-title>
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="deleteItemConfirm">löschen</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          <v-dialog v-if="itemEditInfo" v-model="dialogEditInfo" max-width="600px">
            <v-card>
              <v-card-title class="text-h5 ">Info Text:</v-card-title>
              <v-card-text>
                <v-textarea  v-model="itemEditInfo.info" outlined no-resize background-color="grey lighten-4" height="192" hide-details>

                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="dialogEditInfo = false">Schließen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="saveInfo()">Ändern</v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
          <v-snackbar v-model="snackbar.show" :timeout="2000" :color="snackbar.color">
            {{ snackbar.text }}
          </v-snackbar>
        </v-card>

</template>
  
<script>
import axios from "axios";
import helpers from "@/helpers/functions";
import formatMoney from "@/helpers/formatMoney";

export default {
    name: "Abrechnungen",

    data() {
        return {
            backend: process.env.VUE_APP_STRAPI_API_URL,
            referees: [],
            abrechnungen: null,
            abrechnungen_nicht_eingereicht: [],

            suche:'',
            suche_nicht_eingereicht:'',
            dialogDelete:false,
            dialogEditInfo:false,
            snackbar: {show:false,text:''},
            itemToDelete:null,
            itemEditInfo:null,
            toggleNochNichtEingereicht:false,
            stati:[
              {text:'gesendet',icon:'mdi-email-fast-outline',color:'primary'},
              {text:'erledigt',icon:'mdi-check-circle-outline',color:'primary'}
            ],
            newStatus:'',
            headers: [
              { text: 'Wettspiel', value: 'competition.name', align: 'start' },
              { text: 'Turnier Startdatum', value: 'competition.competitionStartDate' , align: 'start' , typ:'datum'},
              { text: 'Turnier Enddatum', value: 'competition.competitionEndDate' , align: 'start' , typ:'datum'},
              { text: 'Referee', value: 'users_permissions_user.username', align: 'start'  },
              { text: 'E-Mail', value: 'users_permissions_user.email', align: 'start' },
              { text: 'Kilometergeld', value: 'milageAllowance' , align: 'end', typ:'euro'},
              { text: 'Spielleiterpauschale', value: 'refereeAllowance', align: 'end' ,typ:'euro'},
              { text: 'Spesen', value: 'sumExpenses', align: 'end' ,typ:'euro-spesen'},
              { text: 'Gesamtbetrag', value: 'invoiceAmount', align: 'end' ,typ:'euro'},
              { text: 'Status', value: 'state', align: 'center' ,typ:'status' },
              { text: 'Kommentar', value: 'comment', align: 'center',sortable: false, },
              { text: 'Info', value: 'info', align: 'center',typ:'info',sortable: false, },
              { text: 'Actions', value: 'actions', sortable: false },
            ],
          headers_nicht_eingereicht: [
              { text: 'Wettspiel', value: 'competition', align: 'start' },
              { text: 'Turnier Startdatum', value: 'competitionStartDate' , align: 'start' , typ:'datum'},
              { text: 'Turnier Enddatum', value: 'competitionEndDate' , align: 'start' , typ:'datum'},
              { text: 'Referee', value: 'username', align: 'start'  },
              { text: 'E-Mail', value: 'email', align: 'start' },
              // { text: 'Kilometergeld', value: 'milageAllowance' , align: 'end', typ:'euro'},
              // { text: 'Spielleiterpauschale', value: 'refereeAllowance', align: 'end' ,typ:'euro'},
              // { text: 'Spesen', value: 'sumExpenses', align: 'end' ,typ:'euro-spesen'},
              // { text: 'Gesamtbetrag', value: 'invoiceAmount', align: 'end' ,typ:'euro'},
              // { text: 'Status', value: 'state', align: 'center' ,typ:'status' },
              // { text: 'Kommentar', value: 'comment', align: 'center',sortable: false, },
              // { text: 'Info', value: 'info', align: 'center',typ:'info',sortable: false, },
              // { text: 'Actions', value: 'actions', sortable: false },
            ],

        };
    },
    async created() {
        await this.reload();
    },
    methods: {
      openDialogItemInfo(item){
        this.itemEditInfo = item;
        this.dialogEditInfo = true;

      },
      async setNewStatus(status, item) {
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${this.backend}/abrechnungens/` + item.id,
          data: {state: status.text},
          headers: {
            Authorization: localStorage.getItem("token") || "",
          },
        };
        await axios.request(config);
        await this.reload();
        this.snackbar.text='Status geändert!'
        this.snackbar.color='green lighten-1'
        this.snackbar.show=true
      },
      async saveInfo() {
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${this.backend}/abrechnungens/` + this.itemEditInfo.id,
          data: {info: this.itemEditInfo.info},
          headers: {
            Authorization: localStorage.getItem("token") || "",
          },
        };
        await axios.request(config);
        this.dialogEditInfo = false;
        this.snackbar.text = 'Info geändert!'
        this.snackbar.color = 'green lighten-1'
        this.snackbar.show = true
      },
      deleteAbrechnung(item){
        this.itemToDelete =item;

        this.dialogDelete = true
      },
      async deleteItemConfirm() {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${this.backend}/abrechnungens/` + this.itemToDelete.id,
          headers: {
            Authorization: localStorage.getItem("token") || "",
          },
        };
       await axios.request(config);

        this.closeDelete();

        this.snackbar.text='Abrechnung gelöscht!'
        this.snackbar.color='green lighten-1'
        this.snackbar.show=true
        await this.reload();
      },
      closeDelete(){
        this.itemToDelete = null;
        this.dialogDelete = false
      },

      getStatusIcon(val){
        switch (val){
          case 'sent':
          case 'gesendet': return 'mdi-email-fast-outline'
          case 'erledigt': return 'mdi-check-circle-outline'
          default: return ''
        }
      },
      getStatusFarbe(val){
        switch (val){
          case 'sent':
          case 'gesendet': return 'yellow lighten-3'
          case 'erledigt': return 'green lighten-3'
          default: return ''
        }
      },
      async reload() {
        // this.abrechnungen = null;
        this.abrechnungen = await this.getAbrechnungen();
      },
      async reload_nicht_eingereicht() {
        this.abrechnungen_nicht_eingereicht = await this.getAbrechnungen_nicht_eingereicht();
      },

      formatMoney(cur){
        return formatMoney(cur) + " €"
      },
      getLocalDateString(d) {
        return helpers.getLocalDateString(d)
      },
      async getAbrechnungen(){

          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${this.backend}/abrechnungens?_limit=-1`,
            headers: {
              Authorization: localStorage.getItem("token") || "",
            },
          };
          const response =  await axios.request(config);
          return response.data;

      },
      async getAbrechnungen_nicht_eingereicht(){
        let response = [];
        const competitions =  await axios.request({
            method: "get",
            maxBodyLength: Infinity,
            url: `${this.backend}/competitions?_limit=-1`,
            headers: {
              Authorization: localStorage.getItem("token") || "",
            },
          });


           const abrechnungen =  await axios.request({
            method: "get",
            maxBodyLength: Infinity,
            url: `${this.backend}/abrechnungens?_limit=-1`,
            headers: {
              Authorization: localStorage.getItem("token") || "",
            },
          });

        for (const competition of competitions.data) {
          if(new Date(competition.competitionEndDate) > new Date()){
            continue;
          }

          for (const referee of competition.referees) {
            if(referee.ist_GVNRW_Mitarbeiter){
              continue;
            }
            let hat_nicht_abgerechnet = true;
            for (const abrechnung of abrechnungen.data) {
              if(referee.id === abrechnung.users_permissions_user.id && abrechnung.competition.id === competition.id) {
                  hat_nicht_abgerechnet = true;
                }
            }
            if(hat_nicht_abgerechnet) {
              response.push({
                competition: competition.name,
                competition_id: competition.id,
                competitionStartDate:this.getLocalDateString(competition.competitionStartDate),
                competitionEndDate:this.getLocalDateString(competition.competitionEndDate),
                user_id:referee.id,
                username:referee.username,
                email:referee.email
              });
            }
          }
        }

          return response;

      },
    },
  watch: {
      toggleNochNichtEingereicht(){
        if(this.toggleNochNichtEingereicht){
          this.reload_nicht_eingereicht();
        }
      },
      dialogEditInfo(){
      if(!this.dialogEditInfo){

        this.itemEditInfo = null;
        this.reload();
      }
    }
  }
};
</script>

<style>
.ta table,
th,
td {
    border-bottom: 1px solid grey;
}
</style>